import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle,faHouseUser, faBullhorn, faComments, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { graphql } from 'gatsby'
import { useIntl } from "@intractive/gatsby-plugin-react-intl"

const MeedoenPage = ({ data }) => {
  const page = data.meedoenpagina
  const { title, metaDescription, text} = page
  const intl = useIntl();
  
  return(
    <Layout>
      <Helmet>
          <title>{title} · STIP</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
      <div className="row main-content">
        <div className="columns">
          <section>
            <h1>{title}</h1>
            <div>
              <p>{intl.formatMessage({id: "Zou je graag wat meer willen doen bij STIP, maar weet je niet zo goed wat je precies kan doen? Dan zit je op deze pagina goed! We zijn altijd op zoek naar nieuw talent om zo onze stad nóg mooier te maken! Zie de flowchart hieronder voor de opties en lees verder voor meer informatie."})}</p>
              <div className="tree">
                <ul>
                  <li>
                    <div id="meedoen"><strong>{intl.formatMessage({id: "Wil jij meedoen?"})}</strong></div>
                    <ul>
                      <li>
                      <div className="subtree">  
                          <p>{intl.formatMessage({id: "Wil je langskomen op onze evenementen in op de hoogte blijven van nieuwtjes?"})}</p>
                        </div>
                        <ul>
                          <li className="second">
                            <a href="#lid">
                              <div className="subtree second">
                                <p>{intl.formatMessage({id: "Word Lid!"})}</p>
                                <div className="fa-layers fa-fw circle-icon"> 
                                  <FontAwesomeIcon icon={faCircle} color="#fee801" transform="grow-70"/>
                                  <FontAwesomeIcon icon={faHouseUser} color="#e31118" transform="grow-35"/>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="subtree">
                          <p>{intl.formatMessage({id: "Lijkt het jou leuk om leiding te geven aan de vereniging en een campagne met ruim 50 deelnemers? "})}</p>
                        </div>
                        <ul>
                          <li className="second">
                            <a href="https://www.stipdelft.nl/actueel/stip-zoekt-een-nieuw-bestuur/">
                              <div className="subtree second">
                                <p>{intl.formatMessage({id: "Meld je aan voor Bestuur!"})}</p>      
                                <div className="fa-layers fa-fw circle-icon"> 
                                  <FontAwesomeIcon icon={faCircle} color="#fee801" transform="grow-70"/>
                                  <FontAwesomeIcon icon={faBullhorn} color="#e31118" transform="grow-35"/>
                                </div>        
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="subtree">
                          <p>{intl.formatMessage({id: "Wil jij meedenken over het onderwerpen in de politiek?"})}</p>
                        </div>
                        <ul>
                          <li className="second">
                            <a href="#werkgroepen">
                              <div className="subtree second">  
                                <p>{intl.formatMessage({id: "Sluit je aan bij een Werkgroep!"})}</p>
                                <div className="fa-layers fa-fw circle-icon"> 
                                  <FontAwesomeIcon icon={faCircle} color="#fee801" transform="grow-70"/>
                                  <FontAwesomeIcon icon={faComments} color="#e31118" transform="grow-35"/>
                                </div>                              
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="subtree">
                          <p>{intl.formatMessage({id: "Wil jij na de verkiezingen de stad besturen?"})}</p>
                        </div>
                        <ul>
                          <li className="second">
                          <a href="#fractie">
                              <div className="subtree second">
                                <p>{intl.formatMessage({id: "Dan is de Fractie wat voor jou!"})}</p>
                                <div className="fa-layers fa-fw circle-icon"> 
                                  <FontAwesomeIcon icon={faCircle} color="#fee801" transform="grow-70"/>
                                  <FontAwesomeIcon icon={faUserTie} color="#e31118" transform="grow-35"/>
                                </div>                            
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <div dangerouslySetInnerHTML={{__html: text.childMarkdownRemark.html,}}/>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default MeedoenPage

export const query = graphql`
  query($node_locale: String) {
    meedoenpagina: contentfulPage(slug: {eq: "meedoen"}, node_locale: {eq: $node_locale}) {
      title
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
